.search {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #000000;
    /* margin-top: 70px;
    display: inline-block; */
}

.lookingForContent {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #000000;
}

.cant {
    padding: 0;
    width: 80%;
}

.cardSettings {
    width: 100%;
}

.searchResults {
    width: 100%;
}

.cantTxt {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #000000;
}
.cantContent {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #4A4A4A;
    padding: 0;
}

.noPadding {
    padding: 0;
}
@media(max-width: 414px){
    .title-62, .title-110, .title-106,.title-109  {
        height: 32px;
        padding-top : 0px;
    }
    
    .caretDownWrapper-63 {
        top : 0px
    }
    
}

/* Results Search Ends */