.about__top__col{
    padding: 0;
    margin: 0;
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)),url('../../../assests/image/about_image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 116.2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about__page__p{
    font-family: Segoe UI;
    font-size: 15px;
    line-height: 24px;
    /* display: flex; */
    align-items: center;
    /* margin-bottom: 5vh */
}
.about__headers{
    font-family: Segoe UI;
    font-size: 30px;
    line-height: normal;
    display: flex;
    align-items: flex-end;
    margin-bottom: 3.8vh;
}