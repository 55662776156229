.storytext {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #323130;
}

.subBtn {
  background: #000000;
  border-radius: 2px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 1vh 2vw;
  color: #ffffff;
}

.inputfile {
  width: 70px;
  height: 100px;
  opacity: 0;
  position: absolute;
  /* z-index: -1; */
}

.tapText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #9b9b9b;
}

.fileInput {
  display: inline-block;
  text-align: center;
}
