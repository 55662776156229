/*! bootstrap3-wysihtml5-bower 2014-06-11 */

ul.wysihtml5-toolbar {
    margin:0;
    padding:0;
    display:block
   }
   ul.wysihtml5-toolbar::after {
    clear:both;
    display:table;
    content:""
   }
   ul.wysihtml5-toolbar>li {
    float:left;
    display:list-item;
    list-style:none;
    margin:0 5px 10px 0
   }
   ul.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
    font-weight:700
   }
   ul.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
    font-style:italic
   }
   ul.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
    text-decoration:underline
   }
   ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
    background-image:none;
    -webkit-box-shadow:inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow:inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
    box-shadow:inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
    background-color:#E6E6E6;
    background-color:#D9D9D9;
    outline:0
   }
   ul.wysihtml5-commands-disabled .dropdown-menu {
    display:none!important
   }
   ul.wysihtml5-toolbar div.wysihtml5-colors {
    display:block;
    width:50px;
    height:20px;
    margin-top:2px;
    margin-left:5px;
    position:absolute;
    pointer-events:none
   }
   ul.wysihtml5-toolbar a.wysihtml5-colors-title {
    padding-left:70px
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=black] {
    background:#000!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=silver] {
    background:silver!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=gray] {
    background:gray!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=maroon] {
    background:maroon!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=red] {
    background:red!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=purple] {
    background:purple!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=green] {
    background:green!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=olive] {
    background:olive!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=navy] {
    background:navy!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=blue] {
    background:#00f!important
   }
   ul.wysihtml5-toolbar div[data-wysihtml5-command-value=orange] {
    background:orange!important
   }
   .glyphicon-quote:before {
    content:"\201C";
    font-family:Georgia,serif;
    font-size:50px;
    position:absolute;
    top:-4px;
    left:-3px;
    max-height:100%
   }
   .glyphicon-quote:after {
    content:"\0000a0"
   }
   