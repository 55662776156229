.addTitle {
  font-family: "Segoe UI";
  font-weight: bold;
  font-size: 30px !important;
  line-height: 53px;
  color: #000000;
}

.grayBox {
  height: max-content;
  padding: 20px;
  padding-left: 0 !important;
  padding-top: 0;
  width: 100%;
  background: #f3f2f1;
  overflow-y: auto;
}

.smallBox {
  width: 31.112737920937043vw;
  height: 29.921259842519685vh;
  border: 1px dashed #b3b0ad;
}
/* #mydropzone{
  border: 1px dashed #b3b0ad;
} */

.sideTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24.7219px;
}

.textIn {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #9b9b9b;
}

.boldText {
  font-weight: bold;
  color: black;
}
.dragText {
  font-family: Poppins;
  color: black;
}
.labeler {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #323130;
}
/* Drop zone styles */

#dropzone {
  /* margin-bottom: 3rem; */
}
.dropzone .dz-message {
  text-align: center;
  /* margin: 2em 0; */
}

.dropzone {
  min-height: 150px;
  /* border: 2px solid rgba(0,0,0,0.3); */
  background: white;
  background: #f3f2f1;
  /* padding: 20px 20px; */
}

.dropzone .dz-message {
  font-weight: 400;
}

.dropzone .dz-message .note {
  font-size: 0.8em;
  font-weight: 200;
  display: block;
  /* margin-top: 1.4rem; */
}

.subs {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14.7219px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #323130;
}

.addText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #9b9b9b;
}

.expHeader1 {
  padding-top: 2vh;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #323130;
}

.textBox {
  width: 100%;
  border: 1px solid #b3b0ad;
  border-radius: 2px;
}

.textBox:hover {
  border: 1px solid black;
}

.submitBtn {
  background: #ffffff;
  border: 1px solid #8a8886;
  border-radius: 2px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 1vh 2vw;
}
