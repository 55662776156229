.SSImg {
  height: 36.267vh;
  width: 100%;
  object-fit: cover;
}

.conflictHead {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #909090;
}

.consMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #4a4a4a;
}

.mainStory {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #4a4a4a;
}

.tagsMobile {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #909090;
}

.tags {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #4a4a4a;
}

.socialSpace {
  margin-right: 2px;
}

.oImgMob {
  display: inline-block;
}

.oPhoto {
  width: 67.012vw;
  height: 35.709vh;
  object-fit: cover;
}

.otherTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.story_sub_content {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.story_sub_img_fit {
  width: 35vmin;
  height: 40vmin;
  object-fit: cover;
  object-position: center;
}

.darkIcon {
  color: black;
  font-size: 20px;
}
