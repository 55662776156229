.search__input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 0;
  height: 32px;
  width: 256px;
  background: transparent;
}
.sub__btn {
  font-family: Helvetica;
  font-size: 11px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f2f2f2;
  width: 50.34px;
  height: 32px;
  background: #000000;
  border-radius: 0;
}
.footer__text__font {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4a4a4a;
}
.footer__header {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.875px;
  color: #000000;
}

.footerBackground {
  background: #f2f2f2;
}

.footerTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4a4a4a;
}

.footerSubTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.875px;
  color: #000000;
}

.copyrightTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #9b9b9b;
}
