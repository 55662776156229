.home__top__col {
  padding: 0;
  margin: 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.54),
      rgba(0, 0, 0, 0.54)
    ),
    url("../../../assests/image/RectangleImage.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 86.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mhome_background {
  padding: 0;
  margin: 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.54),
      rgba(0, 0, 0, 0.54)
    ),
    url("../../../assests/image/RectangleImage.webp");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
p {
  font-size: 15px;
  line-height: 24px;
}
.home__top__dack__content {
  background: #000000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 22px 12px;
  border-bottom: 2px solid #707070;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.home__margin__left__res {
  padding: 0 0 0 1.2%;
  display: flex;
  align-items: center;
}
.hear__story__header {
  font-size: 48px;
  line-height: 64px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.home__top__writeup {
  font-family: poppins;
  font-size: 24.7219px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.name__author__story {
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  margin: 9.2vh 0 5px;
  font-weight: bold;
  border-bottom: 2px solid #707070;
  padding-bottom: 4px;
  /* style={{ borderBottom: "2px solid #707070", margin: "9.2vh 0 5px", }}
    
    */
}
.home__mid__one {
  background: #000000;
  /* height: 65.5vh; */
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-bottom: 15vh;
  padding-top: 15vh;
}
.home__mid__two {
  /* height: 63vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15vh;
  padding-top: 15vh;
}
.text__decorate__italic {
  font-style: normal;
}
.home__mid__three {
  /* height: 63vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #ffffff;
  padding-left: 0;
  padding-bottom: 15vh;
  padding-top: 15vh;
}
.home__main__header {
  font-family: Montserrat;
  font-size: 64px;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
  vertical-align: center;
  align-items: center;
  color: #ffffff;
  fill: solid;
  margin-bottom: 3.83vh;
}
.home__img__wrapper {
  width: 80%;
}
.body__wrapper {
  width: 89.5%;
  margin: auto;
}

/* .stage_wrapper {
  margin-top: 15vh;
  margin-bottom: 15vh;
} */

.hoverLink span {
  color: white !important;
}

/* .description-55:hover {
  color: white;
} */

.welText {
  padding-top: 19.85511811023622vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 39.555px;
  line-height: 48px;
  color: #ffffff;
}

.subWelTxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15.2781px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 90%;
}

.titleMobWhite {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
  color: #ffffff;
}
.titleMobBlack {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
  color: #000000;
}
.popularStory {
  height: 35.10688140556369vw;
  width: 34.195275590551184vh;
  object-fit: cover;
}

.authorTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  border-bottom: 1px solid #cdcdcd;
}

.carouselItem {
  display: inline-block;
  width: 34.195275590551184vh;
}

.storyQuote {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: #ffffff;
}

.sectionBgWhite {
  box-sizing: border-box;
  margin: 0;
}

.sectionBgBlack {
  box-sizing: border-box;
  margin: 0;
  background: black;
}

.tellImg {
  height: 45.888188976377954vh;
  width: 100%;
  object-fit: cover;
}

.tellStoryTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #4a4a4a;
}
.tellStoryTxtWhite {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 60%;
}


.whiteIcon{
  color: white;
  font-size: 24px;
}