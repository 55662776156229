.participate__header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  line-height: normal;
}

.privary_statement {
  font-family: "poppins";
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.chips p {
  display: none;
}

.chips p:hover {
  display: unset;
}