.pTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 3rem;
}

.contentTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
}

.tab {
  border-top: 0.2px solid #000000;
  /* border-bottom: 0.2px solid #000000; */
}

.tab:last-of-type {
  border-top: 0.2px solid #000000;
  border-bottom: 0.2px solid #000000;
}

.tab h5 {
  display: inline-block;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  margin-top: 1vh;
  font-feature-settings: "salt" on;
  color: #000000;
}

.floatSign {
  float: right;
  margin-top: 1.3vh;
}

div[class="card-header"][id="arparticipate"] {
  background-color: #fff;
}

div[class="card-header"]  div h2 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  display: inline-block;
  width: 89%;
}

div[class="accordion"] div[class="card"] {
  border: 1px;
}

div[class="card-body"][id="privacy_content"] p[class="privacy_text"]  {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #000000;
  justify-content: center;
  align-items: center;
  display: flex;
}
