.mobileResultCard {
  box-shadow: 1px 2px 4px #9b9b9b;
  border-radius: 4px;
  width: 100%;
  height: 143.56px;
}

.imageResolution img {
  width: 100%;
  height: 10.31496062992126vh;
  object-fit: cover;
}

.imgAdjust {
  /* border: 1px solid #00f; */
  width: 30%;
  padding: 8px;
}

.space {
  width: 7%;
}
.contentP {
  /* border: 1px solid #ff0; */
  width: 63%;
}

.report {
  font-family: Segoe UI;
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
}
.text-1 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.text-2 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.text-3 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 24px;
  color: #2d9cdb;
  /* margin-left: 8px; */
}

.text-4 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 24px;
  color: #2d9cdb;
}

.text-5 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #828282;
}
.text-6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13.1584px;
  line-height: 10px;
  color: #404040;
  mix-blend-mode: normal;
  opacity: 0.7;
  border: 1px solid #f00;
}
.col-six {
  width: 50%;
}

.fa-col {
  color: #2d9cdb;
}

@media (max-width: 322px) {
  .mobileResultCard {
    height: 165.56px;
  }
}
