.App {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}
h1{
  font-family: Montserrat;
  font-weight: bold;
}
h2{
  font-family: Montserrat;
  font-weight: bold;
}
h3{
  font-family: Montserrat;
  font-weight: bold;
}
h4{
  font-family: Montserrat;
  font-weight: bold;
}
h5{
  font-family: Montserrat;
  font-weight: bold;
}

