.digitalBg {
    color: #fff;
    background-color: #000;
}

.guideline {
    width: 60%;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
}

.digital-text-1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.01em;
}

.noPadding {
    padding: 0;
}

.participate__header_m {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    font-feature-settings: 'salt' on;
}

.text_size_m {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.01em;
}

div[class="card-header"][id="arcdigital"] {
    background-color: #000;
}

div[class="card-body"][id="digital_content"] div p, div[class="card-body"][id="digital_content"] p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: block;
}

div[class="card-body"][id="digital_content"] {
    background-color: #000;
    color: #fff;
}