.name {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  color: #000000;
}

.personDetails {
  margin-top: 2rem;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  color: #333333;
}

.imgWrap {
  position: absolute;
  height: 98.80546075085324vh;
  width: 41.94729136163983vw;
  object-fit: cover;
}

.smallImg {
  height: 29.291338582677167vh;
  object-fit: cover;
  overflow: hidden;
}

.storyHeader {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  width: 70%;
  color: #000000;
}

.storyBrief {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
}

.report {
  text-decoration: underline;
  text-align: center;
  font-family: Segoe UI;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.questionTag {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 53px;
  /* width: 60%; */
  color: #000000;
}
