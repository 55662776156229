.text_size p { 
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-top: 2vh;
}
.icon_style {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: xx-large;
    cursor: pointer;
}