.nav__header {
  padding: 0;
  margin: 0;
}
.navbar-brand {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.navbar__logo {
  /* height: 11.16vh; */
  margin-top: 11vh;
  width: 108.03px;
}
.navbar__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #ffffff;
}
.nav__branding {
  width: 20%;
  margin: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14.3547px;
  line-height: 22px;
  letter-spacing: -0.0657922px;
  color: #2c2e3f;
  background: #ffffff;
}
.nav__bar__font {
  font-style: normal;
  font-weight: 500;
  font-size: 13.1584px;
  line-height: 10px;
}
.donate__btn {
  height: 4vh;
  background: #000000;
  color: white;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 11.9622px;
  line-height: 10px;
}
.nav__link__font {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13.1584px;
  line-height: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: #141517;
  mix-blend-mode: normal;
  opacity: 0.7;
  margin-left: 2.9vw;
}
.nav__link__font:hover {
  text-decoration: none;
  color: #000000;
}

.toggler {
  color: #111111 !important;
  border-color: none !important;
  border: none !important;
  /* float: right !important; */
  margin-left: 85% !important;
}

/* .collapsed {
  display: unset;
} */
.collapseImg {
  display: none;
}
