.addStoriesHeader {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.addStoriesHeader span {
  font-weight: normal;
}

.graySpace {
  padding: 2vh 1vw;
  background: #f3f2f1;
  margin-bottom: 3rem;
}

.enterStoryHeader {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #323130;
}

.floatBtn {
  float: right;
}
