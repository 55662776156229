.table__header{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 37px;
    line-height: 53px;
    color: #FFFFFF;  
    letter-spacing: 0.01em;
    padding: 10px;
    margin: 0 27px;
}
td{
    font-family: Poppins;
    font-size: 24.7219px;
    line-height: 40px;
    vertical-align: baseline;
    padding: 10px;
}

.contentFont{
    font-size: 24.7219px;
     line-height: 40px;
      font-family: Poppins;
      letter-spacing: 0.01em;
}