.profileImg {
  width: 100%;
  height: 38.4258vh;
  object-fit: cover;
}
.details {
  margin-bottom: 0.6rem;
}
.details span {
  display: inline-block;
}

.profileKey {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #333333;
  width: 50%;
}

.profileValue {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #333333;
}

.profileStory {
  border: 0.5px solid #9b9b9b;
  box-sizing: border-box;
}

.profileStory h3 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}

.profileStory p {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
}
.imgSP {
  width: 100%;
  height: 13.351vh;
  object-fit: cover;
}
