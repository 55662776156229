.custom_failure_alert {
  font-family: Poppins;
  font-size: 15px;
  background: rgb(241, 3, 3);
  color: white;
  position: sticky;
  top: 15%;
  left: 78%;
  padding: 2vh 1vw;
  width: 20%;
  border-radius: 4px;
}


.custom_success_alert{
  font-family: Poppins;
  font-size: 15px;
  background:rgb(53, 143, 53);
  color: white;
  position: sticky;
  top: 15%;
  left: 78%;
  padding: 2vh 1vw;
  width: 20%;
  border-radius: 4px;
}