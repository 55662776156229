body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-white-compound-button,
.custom-white-compound-button span {
  color: #333 !important;
  background: white !important;
  transition: color 0.3s;
}

.custom-white-compound-button:hover,
.custom-white-compound-button:hover span {
  color: #000 !important;
  background: white !important;
}

.custom-black-compound-button,
.custom-black-compound-button span {
  color: #ddd !important;
  background: #000 !important;
  transition: color 0.3s;
}

.custom-black-compound-button:hover,
.custom-black-compound-button:hover span {
  color: #fff !important;
  background: #000 !important;
}
