.clickIt {
  cursor: pointer;
}
.stories__big__header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 53px;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
  color: #000000;
  cursor: pointer;
}
.stories__big__header:hover {
  color: #4a4a4a;
  text-decoration: underline;
}
.stories__small__header {
  font-family: Segoe UI;
  font-size: 24.7219px;
  line-height: 33px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #000000;
  cursor: pointer;
}

.stories__small__header:hover {
  color: #4a4a4a;
  text-decoration: underline;
}
.storeis__main__header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 40px;
  line-height: 53px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  margin-bottom: 6vh;
}
/* style={{ margin: "0 0 13.2vh" }}  */
.stories__row__spacing {
  margin: 0 0 13.2vh;
}
.stories__div__spacing {
  margin-bottom: 7.1vh;
}
.dropdown__width {
  width: 64px;
}
.stories__dropdown__btn {
  width: 64px;
  height: 6.4vh;
  color: #ffffff;
  background: #000000;
  border: none;
}
.stories__big__p {
  font-family: "Segoe UI";
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.imgary {
  width: 100%;
  height: 90.39370078740157vh;
  object-fit: cover;
  overflow: hidden;
}

.imgary2 {
  object-fit: cover;
  overflow: hidden;
}

.conText {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #909090;
}

.subConText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #4a4a4a;
}

.storyText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #4a4a4a;
}

.otherTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}

.otherImg {
  display: inline-block;
  width: 20.13177159590044vw;
  height: 43.30708661417323vh;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}

.addHint {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  color: #000000;
}

.subtleText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #4a4a4a;
}

.reportText {
  font-family: Segoe UI;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
  text-align: center;
}

.storiesImg {
  object-fit: cover;
}

.seal {
  font-family: Poppins;
  display: inline;
  background: #e1dfdd;
  border-radius: 2px;
  font-size: 13px;
  color: gray;
  padding: 0.5vh 0.2vw;
}

.sealGreen {
  font-family: Poppins;
  display: inline;
  background: rgb(12, 150, 12);
  border-radius: 2px;
  font-size: 13px;
  color: white;
  padding: 0.5vh 0.2vw;
}

.popularContentFont {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.mobileTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
  display: flex;
  align-items: center;
  color: #000000;
}

.topSpace {
  padding-top: 10vh;
}

.storyTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}

.storySubContent {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
}

.storyCat {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #000000;
}

.author {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #000000;
}

.storiesItem {
  margin-bottom: 2rem;
}

.storyImg {
  width: 100%;
  height: 26.546vmin;
  object-fit: cover;
}

.reportPic {
  font-family: Segoe UI;
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
}
.navigator {
  color: #605e5c;
}

.popTitle {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.popAuthor {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  color: #000000;
}

.popTxt {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #000000;
}

.popCat {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  color: #000000;
}

.popHeader {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  text-transform: capitalize;
}
.extraText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #4a4a4a;
  width: 80%;
}

@media screen and (min-width: 768){
  .addHint {
    line-height: normal;
    font-size: 30px;
  }
}