.typeText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.resultName {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 53px;
  color: #000000;
}

.cardMainSec span {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #2d9cdb;
  margin-right: 3rem;
}

.cardMainSec i {
  margin-right: 0.6rem;
}

.statusText {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #828282;
}

.resultImg i {
  font-family: Segoe UI;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
  text-align: center;
}

.resultImg img {
  width: 10.834553440702782vw;
  height: 20.31496062992126vh;
  object-fit: cover;
}
