.digitize__small__p{
    font-family: Segoe UI;
    font-size: 15px;
    line-height: 24px;
}
.digitize__big__p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24.7219px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.text_size p { 
    font-size: 15px !important;
    line-height: 30px !important;
    letter-spacing: 0.01em;
    margin-top: 2vh;
}

.digital__header {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 30px !important;
    line-height: normal !important;
  }

  .qst__header__digital {
    font-family: Segoe UI;
    font-size: 40px;
    line-height: 53px;
    display: flex;
  }