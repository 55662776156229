.questionTxt {
  display: inline-block;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  width: 80%;
  /* or 140% */

  font-feature-settings: "salt" on;

  color: #ffffff;
}

.answerTxt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.qAndA {
  border-top: 0.2px solid #ffffff;
}

.faqQA div[class="card-header"] {
  background: #000000;
  color: #ffffff;
  display: inline-block;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
}

.faqQA div[class="card-body"] {
  background: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.faqQA div[class="accordion"] div[class="card"] {
  border: 0.1px solid #ffffff !important;
  /* border-bottom: 0.2px solid #ffffff; */
}
