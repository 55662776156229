.box label {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #323130;
  margin-bottom: 1rem;
}

.box input {
  margin-right: 0.4rem;
}

.shade {
  background: #e1dfdd;
  width: 16.98389458272328vw;
  height: 5.039370078740157vh;
  margin-bottom: 2.2rem;
}

.shade label {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #201f1e;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
